import logo from "./logo.svg";
import logoWhite from "./logo_white.svg";
import jtalentLogo from "./jtalent_logo.png";
import jcartLogo from "./jcart_logo.png";

import "./App.css";

function App() {
  return (
    <div className="h-screen flex flex-col">
      <header className="flex bg-white border-b border-gray-300 px-4 lg:px-6 py-2.5">
        <nav>
          <a href="/" className="flex items-center">
            <img src={logo} className="mr-3 h-6 sm:h-9" alt="JPrime Logo" />
          </a>
        </nav>
      </header>
      <main className="flex flex-1 items-center">
        <div className="container mx-auto p-4">
          <div className="grid lg:grid-cols-2 gap-4 place-content-center">
            <div className="space-y-3 items-center">
              <div>
                <h1 className="text-5xl">
                  JPrime Enterprises (OPC) Private Limited
                </h1>
                <span className="w-16 border-b border-red-500 h-1 inline-block"></span>
              </div>
              <p className="text-2xl text-slate-600">
                To provide specialized and innovative services to clients in
                various industries. Our objectives encompass establishing a
                strong brand presence, cultivating strategic partnerships. We
                aim to contribute positively to both the business landscape and
                the communities we serve.
              </p>
              <p className="text-sm">CIN: U78300TN2024OPC171884</p>
            </div>
            <div className="grid lg:grid-cols-2 gap-4">
              <a
                className="bg-white p-4 space-y-4 rounded border border-gray-200 shadow-sm place-content-center"
                href="https://jtalent.in"
              >
                <img
                  src={jtalentLogo}
                  alt="JTalent Logo"
                  className="h-24 mx-auto"
                />
                <p className="text-center">
                  Hiring made easy. Helping businesses to identify best software
                  engineers, designers, finance experts, product managers, and
                  project managers.
                </p>
              </a>
              <a
                className="bg-white p-4 rounded border border-gray-200 shadow-sm space-y-4 place-content-center"
                href="https://jcart.in"
              >
                <img
                  src={jcartLogo}
                  alt="JCart Logo"
                  className="h-24 mx-auto"
                />
                <p className="text-center">
                  Everyday essentials under one roof. Selling a wide variety of
                  products, including groceries, kitchenware, clothing, and
                  more.
                </p>
              </a>
            </div>
          </div>
        </div>
      </main>
      <footer className="flex flex-col bg-slate-950 items-center p-4 justify-center space-y-4">
        <img src={logoWhite} className="h-6" alt="JPrime Logo" />
        <p className="text-white text-sm">
          &copy; {new Date().getFullYear()}, JPrime Enterprises (OPC) Pvt. Ltd.
          All Rights Reserved.
        </p>
      </footer>
    </div>
  );
}

export default App;
